<template>
  <div class="password_reset" v-if="$route.query.token">
    <b-container>
      <b-row class="password_reset__wrapper" align-h="center" align-v="center">
        <b-col cols="12" md="6">
          <b-form @submit="resetPassword">
            <b-form-group label="Пароль" label-for="password">
              <b-form-input
                id="password"
                type="password"
                v-model="password"
                required
              ></b-form-input>
            </b-form-group>

            <b-form-group
              label="Пароль еще раз"
              label-for="password_confirmation"
            >
              <b-form-input
                id="password_confirmation"
                type="password"
                v-model="password_confirmation"
                required
              ></b-form-input>
            </b-form-group>

            <b-button class="main__button" type="submit" :disabled="disabled"
              >Обновить пароль</b-button
            >
          </b-form>

          <p v-if="error !== null" class="error_block">
            <span class="accent" v-html="error"></span>
          </p>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "PasswordReset",
  data() {
    return {
      password: null,
      password_confirmation: null,
      error: null,
      disabled: false,
    };
  },
  methods: {
    async resetPassword(ev) {
      ev.preventDefault();

      try {
        this.error = null;
        this.disabled = true;

        await axios.post("reset_password", {
          token: this.$route.query.token,
          password: this.password,
          password_confirmation: this.password_confirmation,
        });

        this.$bvToast.toast("Пароль успешно изменен");

        setTimeout(() => {
          this.$router.push({ name: "Login" });
        }, 3000);
      } catch (error) {
        this.disabled = false;
        this.error =
          "Ошибка при восстановлении пароля.<br /> Убедитесь, что Вы перешли по актуальной ссылке и в ее целостности, пароль не меньше 6 символов.";
      }
    },
  },
};
</script>

<style lang="scss">
.password_reset__wrapper {
  padding-top: 25vh;
}
</style>
